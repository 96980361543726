//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseButton',
  props: {
    path: {
      type: String,
      required: false,
      default: '',
    },
    external: {
      type: Boolean,
      required: false,
      default: false,
    },
    target: {
      type: String,
      required: false,
      default: '_self',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    noFollow: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconDirection: {
      type: String,
      default: 'left',
      validator: (direction) => ['left', 'right'].includes(direction),
    },
    variant: {
      type: String,
      default: 'standard',
      validator: (val) => ['standard', 'link'].includes(val),
    },
    preserveQuery: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hovered: false,
    styles: {
      '--x': '',
      '--y': '',
    },
  }),
  computed: {
    cType() {
      return this.$props.path ? 'BaseLink' : 'button';
    },
    isLinkVariant() {
      return this.$props.variant === 'link';
    },
    baseClass() {
      return this.isLinkVariant ? 'button-link' : 'button';
    },
  },
  methods: {
    hover(event) {
      if (!this.isLinkVariant && !this.$props.disabled) {
        const rect = event.target.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        this.styles['--x'] = `${x}px`;
        this.styles['--y'] = `${y}px`;
        this.$emit('mousemove', event);
      }
    },
    handleMouseEnter(event) {
      if (!this.$props.disabled) {
        this.hovered = true;
        this.$emit('mouseenter', event);
      }
    },
    handleMouseLeave(event) {
      this.hovered = false;
      this.$emit('mouseleave', event);
    },
  },
};
