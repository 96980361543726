import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=2a9d8d54&scoped=true&"
import script from "./NavigationDrawer.vue?vue&type=script&lang=js&"
export * from "./NavigationDrawer.vue?vue&type=script&lang=js&"
import style0 from "./NavigationDrawer.vue?vue&type=style&index=0&id=2a9d8d54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9d8d54",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuButton: require('/usr/src/web-www/components/organisms/header-default/MenuButton.vue').default,NavigationAccordion: require('/usr/src/web-www/components/molecules/main-navigation/NavigationAccordion.vue').default,BaseButton: require('/usr/src/web-www/components/atoms/base-button/BaseButton.vue').default,Drawer: require('/usr/src/web-www/components/molecules/drawer/Drawer.vue').default})
